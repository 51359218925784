<template>
  <div class="content">
      <router-view />
    </div>
</template>

<style scoped>
.content {
  min-height: 45vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}


@media (min-width: 576px) {
  .content {
      max-width: 540px;
  }
}

@media (min-width: 768px) {
  .content {
      max-width: 720px;
  }
}

@media (min-width: 992px) {
  .content {
      max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .content {
      max-width: 1140px;
  }
}


</style>
