import { createWebHistory, createRouter } from "vue-router";
import CompHome from "@/views/CompHome.vue";
import CompConsultarAnalitico from "@/views/CompConsultarAnalitico.vue";
import CompVerificarAnalitico from "@/views/CompVerificarAnalitico.vue";
import CompFaq from "@/views/CompFaq.vue";

import CompLogin from "@/views/CompLogin";

const routes = [
  {
    path: "/",
    name: "Home",
    component: CompHome,
  },
  {
    path: "/consultar-analitico",
    name: "consultar-analitico",
    component: CompConsultarAnalitico,
  },
  {
    path: "/verificar-analitico",
    name: "verificar-analitico",
    component: CompVerificarAnalitico,
  },
  {
    path: "/preguntas-frecuentes",
    name: "preguntas-frecuentes",
    component: CompFaq,
  },
  { path: "/login",
   name: "login",
    component: CompLogin,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;