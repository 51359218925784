<template>

    <div class="consultar-analitico">
      
      <h1 class="mb-3">Consultar Certificado</h1>

      <p class="mt-5">Este servicio permite descargar su certificado de actividades de capacitación concluidas promovidas por el INAP o las jurisdicciones, al cual se le ha aplicado un sello de tiempo de la <a href="https://bfa.ar/" target="_blank" alt="Link a Blockchain Federal Argentina (BFA)" rel="nofollow"><strong>Blockchain Federal Argentina (BFA)</strong></a>.</p> 
      
      <p>El servicio generará un hash (un código criptográfico asociado a dicho certificado) que será enviado a la blockchain. A continuación se descargará automáticamente su certificado emitido por el INAP.</p>

      <p v-if="1 == store.isAdminLogueado" class="mx-auto" style="text-align:center"><strong>Ingrese número de CUIL sin guiones ni espacios.</strong></p>
      <p v-else class="mx-auto" style="text-align:center">Haga clic en el siguiente botón para consultar su certificado.</p>

      <!-- Formulario consultar analítico-->
      <form class="mx-auto" v-on:submit.prevent="consultarAnalitico">
        <div class="col-auto">
          <label for="cuil" class="visually-hidden">CUIL</label>
          <input id="cuil" v-if="1 == store.isAdminLogueado" type="number" v-model="nuevoAnalitico.cuil" placeholder="CUIL" required class="input-campo input-form-control form-control">
        </div>
        <div class="col-auto">
          <button class="boton-submit btn" type="submit">Consultar certificado</button>
        </div>
      </form>

      
      <!-- Mensajes de error-->
      <div class="errores">
        <div class="errores-mensaje" v-if="errors.length">
          <p class="mx-auto" style="text-align: center;"><b>Por favor corrija el dato ingresado:</b></p>
          <ul class="px-0">
            <li v-for="error in errors" :key="error.id"><div class="alert alert-danger" role="alert">{{ error }}</div></li>
          </ul>
        </div>
      </div>

      <div class="alert alert-light shadow-sm rounded mt-3 mb-3" role="alert">
        <strong>Importante:</strong> La información contenida en el certificado nunca es almacenada en la red de la BFA, garantizando su privacidad.
      </div>


    </div> <!-- /. consultar-analitico -->





    
    <!-- Mostrar resultado-->
    <div v-if="resultado" class="container-fluid">
      <!-- Mostrar datos de la persona-->
      <div class="persona-nombre" v-for="analitico in analiticos.slice(0,1)" :key="analitico.id">
        <h3>{{analitico.nombre}} {{analitico.apellido}}</h3>
        <h5>CUIL: {{analitico.cuit}}</h5>
      </div>
      
      <!-- Mostrar tabla con analítico-->
      <div style="overflow-x:auto;">
        <table class="tabla-analiticos table table-responsive table-striped table-bordered" cellspacing="0">
          <thead>
            <tr>
              <th class="table-header">Nombre de la actividad</th>
              <th class="table-header">Código de actividad</th>
              <th class="table-header">Disposición</th>
              <th class="table-header">Código de comisión</th>
              <th class="table-header">Fecha de finalización</th>
              <th class="table-header">Duración en horas</th>
              <th class="table-header">Créditos</th>
              <th class="table-header">Estado</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(analitico, index) in analiticos" :key="index">
              <!-- Impar -->
              <td v-if="index % 2 === 0" class="impar nombre">{{analitico.actividad}}</td>
              <td v-if="index % 2 === 0" class="impar">{{analitico.cod_actividad}}</td>
              <td v-if="index % 2 === 0" class="impar">{{analitico.disposicion}}</td>
              <td v-if="index % 2 === 0" class="impar">{{analitico.cod_comision}}</td>
              <td v-if="index % 2 === 0" class="impar">{{analitico.fecha_finalizacion}}</td>
              <td v-if="index % 2 === 0" class="impar">{{analitico.duracion_hs}}</td>
              <td v-if="index % 2 === 0" class="impar">{{analitico.creditos}}</td>
              <td v-if="index % 2 === 0" class="impar">{{analitico.estado}}</td>

              <!-- Par -->
              <td v-if="index % 2 !== 0" class="par nombre">{{analitico.actividad}}</td>
              <td v-if="index % 2 !== 0" class="par">{{analitico.cod_actividad}}</td>
              <td v-if="index % 2 !== 0" class="par">{{analitico.disposicion}}</td>
              <td v-if="index % 2 !== 0" class="par">{{analitico.cod_comision}}</td>
              <td v-if="index % 2 !== 0" class="par">{{analitico.fecha_finalizacion}}</td>
              <td v-if="index % 2 !== 0" class="par">{{analitico.duracion_hs}}</td>
              <td v-if="index % 2 !== 0" class="par">{{analitico.creditos}}</td>
              <td v-if="index % 2 !== 0" class="par">{{analitico.estado}}</td>
            </tr>
        </tbody>
        </table>
      </div>

      <!-- Mostrar botón de descarga-->
      <div class="boton-descarga mb-5">
        <button v-if="this.botonHabilitado" type="button" class="boton-submit btn" v-on:click="descargarAnalitico">Descargar Certificado en PDF</button>
        
        <div v-else class="mx-auto">
          <span class="loader mb-2"></span>
          <p class="texto-espera">Aguarde un momento...</p>
        </div>
      </div>

      <!-- Mensajes de error-->
      <div class="errores">
        <div class="errores-mensaje mb-5" v-if="pdferrors.length">
          <ul>
            <li v-for="pdferror in pdferrors" :key="pdferror.id"><div class="alert alert-danger" role="alert">{{ pdferror }}</div></li>
          </ul>
        </div>
      </div>

      <!-- Mensajes de Descarga-->
      <div class="mensaje-descarga mb-5" v-if="mensajeDescarga.length">
        <div class="alert alert-success" role="alert">{{ mensajeDescarga }}</div>
      </div>

    </div>



</template>



<script>

import axios from "axios";
axios.defaults.withCredentials = true; 

import { store } from './../components/store'

export default {
  data() {
    return {
      analiticos: [],
      resultado: false,
      botonHabilitado: true,
      nuevoAnalitico: {
      },
      errors: [],
      mensajeDescarga: '',
      pdferrors: [],
      store
    }
  },
  created() {
    if(store.logueado) {
      console.log('Usuario logueado');
    } else {
      this.$router.push("/login");
      console.log('Usuario no logueado');
    }
  },
  methods: {
    deshabilitarBoton() {
      this.botonHabilitado = false;
      console.log('botonHabilitado: ', this.botonHabilitado);
    },
    habilitarBoton() {
      this.botonHabilitado = true;
      console.log('botonHabilitado: ', this.botonHabilitado);
    },
    descargarAnalitico() {

      this.deshabilitarBoton();
      /* Agregar cuil automáticamente si es un usuario común */
      if ( 0 == store.isAdminLogueado ) {
        console.log("Descarga de Usuario Normal");
        this.nuevoAnalitico.cuil = store.usernameLogueado;
        console.log('nuevoAnalitico: ' + this.nuevoAnalitico.cuil);
      } else {
        console.log("Descarga de Usuario Admin");
      }

      this.pdferrors = [];

      this.mensajeDescarga = '';

      // Descargar analítico.

      // Config headers and responseType.
      let config = { 
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        responseType: 'blob'
      };

      let urlAPI = process.env.VUE_APP_RUTA_ANALITICOS_BACKEND + "/analiticos/" + this.nuevoAnalitico.cuil + '?format=pdf';
      
      axios.get(urlAPI, config )
      .then(response => {
          console.log(response);
          let FILE = window.URL.createObjectURL(new Blob([response.data]));
          let docUrl = document.createElement('a');
          docUrl.href = FILE;
          docUrl.setAttribute('download', 'certificado-' + this.nuevoAnalitico.cuil + '.pdf');
          document.body.appendChild(docUrl);
          docUrl.click();
          this.habilitarBoton();
          this.mensajeDescarga = 'Certificado generado correctamente. Recuerde que en cualquier momento podrá verificar la autenticidad del mismo en este mismo sitio.';
      })
      .catch(pdferror => {
        console.log(pdferror)
        this.habilitarBoton();
        this.pdferrors.push('Error al descargar PDF. Servicio temporalmente no disponible. Intente en unos minutos.');
      })
    },
    consultarAnalitico() {

      /* Agregar cuil automáticamente si es un usuario común */
      if ( 0 == store.isAdminLogueado ) {
        console.log("Consulta de Usuario Normal");
        this.nuevoAnalitico.cuil = store.usernameLogueado;
        //console.log('1-nuevoAnalitico: ' + this.nuevoAnalitico.cuil);
      } else {
        console.log("Consulta de Usuario Admin");
      }

      this.resultado = false;

      this.errors = [];

      if (!this.nuevoAnalitico.cuil || this.nuevoAnalitico.cuil === undefined) {
        //console.log('2-nuevoAnalitico: ' + this.nuevoAnalitico.cuil);
        this.errors.push('El campo CUIL es requerido.');
        return;
      }

      if (this.nuevoAnalitico.cuil) {
        if (this.nuevoAnalitico.cuil.toString().length !== 11) {
          this.errors.push('Ha ingresado un CUIL inválido.');
          return;
        }
      }
    
      let urlAPI = process.env.VUE_APP_RUTA_ANALITICOS_BACKEND + "/analiticos/" + this.nuevoAnalitico.cuil + '?format=json';

      //Consulto analítico segun CUIL ingresado en el formulario.
      axios.get(urlAPI)
      .then(response => {
        this.resultado = true;
        this.mensajeDescarga = '';
        console.log(response.data);
        this.analiticos = response.data;
      })
      .catch(error => {
        console.log(error)
        this.resultado = false;
        this.errors.push('Solicitud errónea. Por favor intente nuevamente. Si el problema persiste comuníquese con la mesa de ayuda.');
      })

    }
  }
}
</script>

<style scoped>

.persona-nombre {
    margin-top: 50px;
}

.consultar-analitico {
    margin-bottom: 50px;
    min-height: 60vh;
}
.consultar-analitico h1,
.consultar-analitico p{
  text-align: left;
}
.tabla-analiticos {
  font-size: 0.8rem;
  margin-top: 30px;
  margin-bottom: 30px;
}


h1{
  font-size: 2.2rem;
  font-family: 'Lora';
}
.tabla-analiticos th {
    border-bottom: 1px solid #000;
    padding: 10px 7px;
}

.tabla-analiticos td {
    padding: 10px 7px;
}

.tabla-analiticos td.nombre {
    text-align: left;
}

a {
  height: 100px;
  width: 100px;
  color: #000;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.errores {
  text-align: center;
}

.errores ul {
  list-style-type: none;
}

form {
  text-align: center;
}

.texto-espera {
  font-size: .85rem;
  padding-top: 6px;
  padding-bottom: 28px;
}

.table-header{
  background: #252C50;
  color: white;
  vertical-align: middle;
  text-transform: uppercase;
  font-family: 'Montserrat';
  font-weight: 500;
  border-bottom: 0 !important;
}

.boton-submit  {
    padding: 0.4rem 2rem;
    font-size: .9rem;
    line-height: 1.2;
    border-radius: 3rem;
    background: #252C50;
    border: none !important;
    height: 33px;
    font-family: 'Montserrat';
    font-weight: 500;
    color: white;
    min-width: 100px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.btn:active,
.btn:focus,
.btn:hover {
  color: #fff;
  background: #252C50;
}

.input-form-control {
    border-radius: 0;
    height: 35px;
    font-size: .9rem;
    line-height: 1rem;
    padding-top: 2px;
    padding-bottom: 1px;
    padding-left: 7px;
    color: #666;
    margin-bottom: 25px !important;
    border: 1px solid #666;
}
.form-control {
    display: block;
    width: auto;
    margin: auto;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 50px !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

td.par {
  background-color: #fff;
}

.loader {
  width: 30px;
  height: 30px;
  border: 5px solid #464646;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
} 

</style>
