<template>
    <div class="container faq mb-5 pb-5">

        <h1 class="mb-3">Preguntas frecuentes</h1>
        <div class="row">
        <div class="col-md-12">


          <div class="accordion accordion-flush mt-5" id="accordionFlushFaq">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                  <strong>¿Cuál es la validez normativa del certificado? ¿Qué diferencia tiene con las constancias emitidas por el campus y las enviadas por GDE?</strong>
                </button>
              </h2>
              <div id="flush-collapseOne" class="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushFaq">
                <div class="accordion-body"><p>Ninguna, tiene el mismo valor que el certificado descargado del campus virtual. Al no contar con firma certificada de un funcionario de INAP con competencia en acreditación de actividades de capacitación, no es válido como certificación de créditos a aplicar en la carrera.</p></div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                  <strong>¿Se generará otro sello cada vez que un participante apruebe un nuevo curso y se agregue a la lista? Creemos que es importante aclarar cómo se actualiza.</strong>

                </button>
              </h2>
              <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushFaq">
                <div class="accordion-body"><p>Con cada descarga se genera un nuevo sello, siempre y cuando la información haya cambiado respecto de una descarga previa.</p></div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                  <strong>¿En qué se diferencia este documento con las constancias emitidas por el campus? ¿Qué validez normativa tiene? ¿Es un documento legal?</strong>
                </button>
              </h2>
              <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushFaq">
                <div class="accordion-body"><p>No es un documento legal y tiene el mismo valor normativo (ninguno) que el certificado descargado del campus. La diferencia radica en que en este caso, se garantiza la autenticidad, es decir, que fue emitido por el INAP, y la integridad, pues permite verificar que el documento no haya sido alterado luego de ser sellado.</p></div>
              </div>
            </div> <!-- .accordion-item -->

            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingFour">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseThree">
                  <strong>¿Qué es Blockchain?</strong>
                </button>
              </h2>
              <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushFaq">
                <div class="accordion-body"><p>Blockchain es una tecnología diseñada para administrar un registro de datos online, caracterizada por ser transparente y prácticamente incorruptible.</p></div>
              </div>
            </div> <!-- .accordion-item -->

            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingFive">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseThree">
                  <strong>¿Cómo funciona?</strong>
                </button>
              </h2>
              <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushFaq">
                <div class="accordion-body"><p>A grandes rasgos, Blockchain se puede pensar como un libro contable, una bitácora o una base de datos donde solo se puede ingresar entradas nuevas y donde todas las existentes no se pueden modificar ni eliminar. Esas entradas, llamadas transacciones, se agrupan en bloques que se van agregando, sucesivamente, al registro en forma de cadena secuencial, cada uno de ellos relacionado necesariamente con el anterior.</p>

                  <p>En ese esquema, si quisiéramos corregir información ya registrada, solo lo podemos hacer mediante el agregado de nueva información. Los datos originales siempre van a permanecer y pueden ser fiscalizados en cualquier momento.</p>

                  <p>Características:</p>
                      <ul>
                        <li>Por su naturaleza, blockchain permite realizar una serie de operaciones combinadas que por primera vez se pueden utilizar de manera conjunta en el mundo digital.</li>
                      <li>Poder garantizar en cada transacción la identidad de las partes involucradas, ya que todas las transacciones son firmadas criptográficamente.</li>
                      <li>Certificación de la fecha y hora de la transacción.</li>
                      <li>La información es inmutable e inalterable: no es posible modificarla ni borrarla.</li>
                      <li>Toda la información almacenada en la cadena es completamente auditable: se incorpora de forma pública y visible para todos los usuarios.</li>
                      <li>Blockchain funciona sin intermediarios: no hace falta una persona, empresa o institución que legitime la información guardada en la cadena, ya que es segura por naturaleza.</li>
                      <li>De la misma forma que en un libro contable, las entradas no se pueden borrar o modificar, solo agregar. Una blockchain siempre suma nueva información, crece permanentemente.</li>
                      </ul>
                  </div>
                </div>
            </div> <!-- .accordion-item -->

            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingSix">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseThree">
                  <strong>¿Qué es BFA?</strong>
                </button>
              </h2>
              <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushFaq">
                <div class="accordion-body">

                  <p>Blockchain Federal Argentina es una plataforma multiservicios abierta y participativa pensada para integrar servicios y aplicaciones sobre blockchain. Una iniciativa confiable y completamente auditable que permita optimizar procesos y funciona como herramienta de empoderamiento para toda la comunidad.</p>

                  <p>Blockchain Federal Argentina fue concebida dentro de un espacio de trabajo colaborativo, y apunta a reproducir ese patrón como columna vertebral de la plataforma.</p>

                  <p>Diseñada para potenciarse a través de los aportes de sectores públicos, privados, académicos y de la sociedad civil, BFA opta por una estrategia donde la participación de toda la comunidad es esencial, desde la ingeniería organizacional hasta el despliegue de la infraestructura.</p>

                  <p>Siguiendo el modelo de Múltiples Partes Interesadas, Blockchain Federal Argentina mantiene un modelo de gobernanza que asegura la representación de todos los sectores en la toma de decisiones. Pero al ser una plataforma pública, su uso no estará restringido a las organizaciones que participen del consorcio. Toda la comunidad tiene las puertas abiertas para participar en BFA.</p>

                  <p>Individuos, organismos, instituciones o empresas de cualquier sector interesados en desplegar aplicaciones y servicios aprovechando todas las características de la plataforma, o simplemente contribuir al primer desarrollo de esta índole en el país, pueden sumarse a la iniciativa y comenzar a participar.</p>

                  <p>El diseño tanto técnico como de gestión de Blockchain Federal Argentina no solo fue pensado para garantizar que la iniciativa fuera escalable gracias a la incorporación de nuevos participantes, sino también a asegurar su continuidad en el tiempo: que perdure más allá de las personas e instituciones que lo gestaron gracias a un modelo de trabajo horizontal y colaborativo.</p>

                </div>
              </div>
            </div> <!-- .accordion-item -->

          </div><!--  .accordion -->

         
          

    
          
        </div>

      </div>
    </div>

</template>


<style scoped>

.faq h1,
.faq p{
  text-align: left;
}

h1 {     
  font-size: 2.2rem;
  font-family: 'Lora';
  color: #252C50;
  font-weight: 600;
}
ul, li{
  text-align: left;
}

button{
  white-space: normal;
}
button:hover,
button.active:hover{
  color:white !important;
  border:none !important;
}

.accordion-button:not(.collapsed){
  color:white !important;
  background-color: #252C50 !important;
}
.accordion-header:hover .accordion-button:after,
.accordion-button:not(.collapsed):after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed{
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.accordion-button:focus{
  box-shadow: none !important;
}


@media (min-width:1400px){
    .txt-home{
      padding-right: 4rem;
    }
}
</style>