import { reactive } from 'vue'
import axios from "axios";
axios.defaults.withCredentials = true;

export const store = reactive({
  logueado: false,
  usernameLogueado: null,
  isAdminLogueado: null,
  userfullnameLogueado: null,
  mensajeLogin: null,
  loguearse() {
    this.logueado = true;
  },
  checkLocalstorage() {
    console.log("------checkLocalstorage");
    if ( localStorage.getItem('ls_user') ) {
      console.log("------recupero sesión");
      this.logueado = true;
      this.usernameLogueado = localStorage.getItem('ls_user');
      this.userfullnameLogueado = localStorage.getItem('ls_name');
      this.isAdminLogueado = localStorage.getItem('ls_options');
    }
  },
  desloguearse() {
    
    let urlAPI = process.env.VUE_APP_RUTA_ANALITICOS_BACKEND + '/auth/logout/' + this.usernameLogueado;

      //Le pego al logout.
      axios.post(urlAPI)
      .then(response => {
        console.log(response.data);
        this.logueado = false;
        this.usernameLogueado = null;
        this.userfullnameLogueado = null;
        this.isAdminLogueado =  null;
        localStorage.removeItem('ls_user');
        localStorage.removeItem('ls_name');
        localStorage.removeItem('ls_options');
      })
      .catch(error => {
        console.log(error)
      })
  }
})