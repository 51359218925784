import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// https://matteo-gabriele.gitbook.io/vue-gtag/
import VueGtag from "vue-gtag";

import axios from 'axios'
import { store } from './components/store'

//Axios middleware
axios.interceptors.response.use(
  function (response) {
    return response;
  }, 
  function (error) {
    if(error.response.status == 401){
      store.logueado = false;
      store.usernameLogueado = null;
      store.userfullnameLogueado = null;
      store.isAdminLogueado =  null;
      store.mensajeLogin = 'La sesión ha expirado. Debe ingresar nuevamente.';
      localStorage.removeItem('ls_user');
      localStorage.removeItem('ls_name');
      localStorage.removeItem('ls_options');
      router.push({
        name: "login",
      })
    }
    return Promise.reject(error);
  }
);

const app = createApp(App)
app.use(router).use(VueGtag, {  config: { id: "G-9YDRDQ27DW" }}).mount('#app')

//Chequeo si el usuario tenia sesión al refrescar la página.
store.checkLocalstorage();