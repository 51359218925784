<template>
<header>
    <div class="container pt-2 pb-2 px-0">
      
          <div class="row">
            <div class="col-md-3 col-12 d-flex">
              <a class="navbar-brand" href="/">
                <div class="logo mt-2">
                  <img alt="INAP" src="@/assets/logo-inap.png" title="IMAGEN: Logotipo INAP" tabindex="0">
                </div>
              </a>
            </div>
        <div class="col-md-9 col-12">
          <nav class="navbar navbar-expand-md navbar-light bg-light bg-white d-flex flex-row-reverse py-0 pt-sm-2">
            <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end" id="navbarResponsive">
              <div id="nav">
                  <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                      <!-- <li class="nav-item"><router-link class="nav-link" to="/" alt="Inicio">Inicio</router-link></li> -->
                      <li v-if="store.logueado" class="nav-item"><router-link class="nav-link" to="/consultar-analitico" alt="Consultar Certificado">Consultar Certificado</router-link></li>                      
                      <li class="nav-item"><router-link class="nav-link" to="/verificar-analitico" alt="Verificar Certificado">Verificar Certificado</router-link></li>                        
                      <li class="nav-item"><router-link class="nav-link" to="/preguntas-frecuentes" alt="Inicio">Preguntas frecuentes</router-link></li>  
                      <li v-if="store.logueado" class="nav-item"><span class="nav-link"><strong>{{ store.userfullnameLogueado}}</strong></span></li>
                      <li v-if="store.logueado" class="nav-item"><router-link class="nav-link login" to="/login" @click="store.desloguearse()" alt="Salir">Salir</router-link></li>
                      <li v-if="!store.logueado" class="nav-item"><router-link class="nav-link login" to="/login" alt="Ingresar">Ingresar</router-link></li> 
                  </ul>
              </div>
            </div>
            
        </nav>
        </div>
      
      </div>
      
    </div>

</header>


    

</template>

<script>

import { store } from './store'

export default {
  data() {
    return {
      store
    }
  }
}

</script>

<style scoped>
header {
  background-color: #fff;
  box-shadow: 0 1px 10px rgb(0 0 0 / 10%);
  margin-bottom: 90px;
  border-bottom: 7px solid #E7BA61;
  min-height: 80px;
}
.logo img{
  max-width: 240px;
  vertical-align: middle;
  object-fit: contain;
  margin-left: 10px;
}

#nav a, #nav span, #nav {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
}
#nav a:hover {
    color: #252C50;
}

#nav button:hover,
header button:hover{
  background-color: #FFF !important;
}
.navbar-toggler{
  margin-top: -55px;
}
.navbar-toggler:focus{
  box-shadow: none !important;
}

.login{
  padding: 8px 25px 7px 25px !important;
  border-radius: 6rem;
  background: #252C50;
  margin-left: 15px;
  height: 33px;
  color: white !important;
  line-height: 18px;
  text-transform: uppercase !important;
  cursor: pointer;
}

.login:focus,
.login:active,
.login:hover{
  color: #fff;
  background: #1b3d65;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
  .container {
      max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
      max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
      max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
      max-width: 1140px;
  }
}
</style>
