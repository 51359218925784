<template>
  <!-- <footer>
    <p class="footer-leyenda">Instituto Nacional de la Administración Pública. Av. Roque Sáenz Peña 511 – Teléfono (5411) 4343 9001 CABA República Argentina</p>
  </footer> -->
  <footer>
    <div class="footer-data">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                </div>
            </div>
        </div>
    </div>
      <div class="container pt-5 pb-5">
        <div class="row">
          <div class="col-md-5 col-sm-12 footer_widget_area_left">
            <!-- <img width="166" height="86" src="@/assets/primero-la-gente.jpg" class="image" alt="primero la gente" loading="lazy" style="max-width: 100%; height: auto;"> -->
            <p class="mt-4">Instituto Nacional de la Administración Pública. Av. Roque Sáenz Peña 511 – Teléfono (5411) 6065-2200 CABA República Argentina</p>
          </div>
          <div class="col-md-7 col-sm-12 footer_widget_area_right">
            <img width="480" height="85" src="@/assets/min_sec_subse_negativo_550x97.png" class="image" alt="" loading="lazy" style="max-width: 100%; height: auto;">
          </div>
        </div>
      </div>
    </footer>
</template>

<style scoped>

footer {
    background: #252C50;
    padding: 0;
}

footer .footer-data {
    background-color: #E7BA61;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
}

footer p {
    color: #ffffff;
    font-size: 12px;
    font-weight: 400 !important;
    line-height: 17px;
    font-family: 'Montserrat' !important;
    margin: 0;
    padding: 0;
}

.footer_widget_area_left img {
  max-width: 110px !important;
  margin-bottom:40px;
}



.footer_widget_area_left,
.footer_widget_area_right{
  text-align: center;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
  .container {
      max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
      max-width: 720px;
  }
  .footer_widget_area_left{
    text-align: left;
    margin-bottom: 0;
  }

  .footer_widget_area_left img{
    max-width: 145px !important;
  }


  .footer_widget_area_right{
    text-align: right;
  }
}

@media (min-width: 992px) {
  .container {
      max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
      max-width: 1140px;
  }
}

</style>

