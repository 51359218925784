<template>
  <div class="container-fluid pb-5" style="min-height: 60vh;">
    <div class="login mx-auto shadow bg-white rounded py-5 mb-5">
      
      <p>Ingrese sus datos aquí:</p>
      <form action class="form" @submit.prevent="autentificarLogin">
        <label for="username" class="visually-hidden">Ingresá tu CUIL o CUIT</label>
        <input
          v-model="username"
          class="input-form-control form-control"
          type="number"
          id="username"
          required
          placeholder="CUIL/CUIT"
        >
        <label for="password" class="visually-hidden">Ingresá tu contraseña</label>
        <input
          v-model="password"
          class="input-form-control form-control"
          type="password"
          id="password"
          placeholder="Contraseña"
        >
        
        <input v-if="this.botonHabilitado" class="boton-submit btn mt-3 mb-4" type="submit" value="Acceder">
        <div v-else class="mx-auto">
          <span class="loader mb-2"></span>
          <p class="texto-espera">Aguarde un momento...</p>
        </div>
      
        <a class="forgot_password d-block mx-auto mb-3" href="https://campus.inap.gob.ar/login/forgot_password.php" target="_blank" alt="¿Olvidó su usuario o contraseña?"><small>¿Olvidó su usuario o contraseña?</small></a>
        
        <!-- Mensajes de error-->
        <div class="errors mx-5">
          <div class="errores-mensaje bg-white" v-if="errors.length">
            <ul class="px-0 py-0" style="list-style: none;">
              <li v-for="errors in errors" :key="errors.id"><div class="alert alert-danger" role="alert">{{ errors }}</div></li>
            </ul>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import axios from "axios";
axios.defaults.withCredentials = true;

import { store } from './../components/store';


export default {

  data() {
    return {
      errors: [],
      username: '',
      password: '',
      botonHabilitado: true,
      store
    }
  },
  mounted() {
    this.revisarMensajes();
  },
  methods: {
    revisarMensajes() {
      console.log('revisarMensajes: ', this.errors);
      // Muestro mensaje de sesión expirada.
      if (store.mensajeLogin) {
        this.errors.push(store.mensajeLogin);
        store.mensajeLogin = null;
      }
    },
    deshabilitarBoton() {
      this.botonHabilitado = false;
      console.log('botonHabilitado: ', this.botonHabilitado);
    },
    habilitarBoton() {
      this.botonHabilitado = true;
      console.log('botonHabilitado: ', this.botonHabilitado);
    },
    autentificarLogin() {

      this.deshabilitarBoton();

      this.errors = [];

      // Config headers.
      let config = { 
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      };

      let urlAPI = process.env.VUE_APP_RUTA_ANALITICOS_BACKEND + "/auth/login";

      const user = 'username=' + this.username + '&password=' + this.password;
      
      axios.post(urlAPI, user, config, {
      }).then((response) => {
        console.log(response);
        store.loguearse();
        this.habilitarBoton();
        //Seteo variables de store.
        store.usernameLogueado = response.data[0].body.username;
        store.userfullnameLogueado = response.data[0].body.fullname;
        store.isAdminLogueado = response.data[0].body.is_admin;
        //Seteo por única vez variable de localstore.
        localStorage.setItem('ls_user', response.data[0].body.username);
        localStorage.setItem('ls_name', response.data[0].body.fullname);
        localStorage.setItem('ls_options', response.data[0].body.is_admin);
        //console.log(response.data);
        console.log(response.data[0].body.username);
        console.log(response.data[0].body.fullname);
        console.log(response.data[0].body.is_admin);
        //console.log(response.data.access_token);
        
        this.$router.push("/");

      })
      .catch(errors => {
        console.log(errors)
        this.habilitarBoton();
        this.errors.push('Login error.');
      })
    }
  }
};
</script>

<style scoped>

.boton-submit  {
    padding: 0.4rem 2rem;
    font-size: .9rem;
    line-height: 1.2;
    border-radius: 3rem;
    background: #252C50;
    border: none !important;
    height: 33px;
    font-family: 'Montserrat';
    font-weight: 500;
    color: white;
    min-width: 100px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.btn:focus,
.btn:active,
.btn:hover {
  color: #fff !important;
  background: #252C50 !important;
}

.input-form-control {
    height: 35px;
    font-size: .9rem;
    line-height: 1rem;
    padding-top: 2px;
    padding-bottom: 1px;
    padding-left: 7px;
    color: #666;
    margin-bottom: 15px !important;
    border: 1px solid #666;
    border-radius: 50px !important;
    max-width: 300px;
    margin: 0 auto;
}
.form-control {
    display: block;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #464646;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.forgot_password{
  color: #464646;
}

.login input::-webkit-outer-spin-button,
.login input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.login input[type=number] {
  -moz-appearance: textfield;
}

.loader {
  width: 30px;
  height: 30px;
  border: 5px solid #464646;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
} 


@media (min-width:768px){
  .login{
    max-width: 300px;
  }
  .input-form-control{
    max-width: 200px;
  }

}

@media (min-width:1600px){
  .login{
    max-width: 380px;
  }
  .input-form-control{
    max-width: 240px;
  }
}

</style>