<template>

    <div class="verificar-analitico">
      <h1 class="mb-3">Verificación de Certificados</h1>

      <p class="mt-5">Este servicio permite demostrar que el contenido de un certificado emitido por el INAP existió en un momento y que desde entonces, no ha cambiado. Al sellar un certificado, cualquiera podrá verificar el día y la hora en que su hash fue almacenado en <a href="https://bfa.ar/" target="_blank" alt="Link a Blockchain Federal Argentina (BFA)" rel="nofollow"><strong>Blockchain Federal Argentina (BFA)</strong></a>.</p>

      <p class="mx-auto mt-5" style="text-align: center;"><strong>Agregue el certificado en PDF para verificar su validez.</strong></p>
      
      <form v-on:submit.prevent="verificarAnalitico">
          <div class="col-auto">
            <label for="pdf_analitico" class="visually-hidden">Subir archivo</label>
            <input id="pdf_analitico" required type="file" @change="onFileUpload" ref="file">
          </div>
          <div class="col-auto">
            <button class="boton-submit btn mt-3" type="submit">Verificar certificado</button>
          </div>
      </form>

      <!-- Mensajes de error-->
      <div class="errores">
        <div class="errores-mensaje" v-if="errors.length">
          <p class="mx-auto" style="text-align: center;"><b>Ha ocurrido el siguiente error:</b></p>
          <ul>
            <li v-for="error in errors" :key="error.id"><div class="alert alert-danger" role="alert">{{ error }}</div></li>
          </ul>
        </div>
      </div>

      <!-- Mensajes de verificacion-->
      <div class="mensaje-verificacion">
        <div v-if="mensajeVerificacion.length">
          <p v-html="mensajeVerificacion"></p>
        </div>
      </div>

      <div class="alert alert-light shadow-sm rounded mt-3 mb-3" role="alert">
        <strong>Importante:</strong> La información contenida en el certificado nunca es almacenada en la red de la BFA, garantizando su privacidad.
      </div>

    </div> 
</template>

<script>

import axios from "axios";

export default {
  data() {
    return {
      analiticos: [],
      resultado: false,
      nuevoAnalitico: {
      },
      errors: [],
      mensajeVerificacion: '',
      mensajeBFA: '',
      FILE: null,
      name: ''
    }
  },
  methods: {
    onFileUpload (event) {
        this.fileUploaded = event.target.files[0];
    },
    verificarAnalitico() {

      // upload file

      this.errors = [];

      this.mensajeVerificacion = '';
      this.mensajeBFA = '';

      const formData = new FormData();

      let config = { 
        headers: { 'Content-Type': 'multipart/form-data', 'accept': 'application/json'},
        type: 'application/pdf'
      }

      //console.log(this.fileUploaded.name);
    
      formData.append('file', this.fileUploaded);
      
      let urlAPI = process.env.VUE_APP_RUTA_ANALITICOS_BACKEND + "/verificar/";

      axios.post(urlAPI, formData, config, {
      }).then((response) => {
        console.log(response.data)
        console.log(response.data.stamped)

        let verified = response.data.stamped;
        
        if (verified === true) {
          let bfaWho = response.data.stamps[0].whostamped;
          let bfaTime = response.data.stamps[0].blocktimestamp;
          let bfaNumber = response.data.stamps[0].blocknumber;
          
          this.mensajeVerificacion = '<div class="alert alert-success" role="alert"><h5 class="mt-1 mx-auto mb-0" style="text-align:center;"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/><path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/></svg> El certificado ha sido verificado correctamente y es genuino.</h5><p style="text-align:center" class="mt-3"><b>Block Number:</b> ' + bfaNumber + ' - <b>Block Time Stamp:</b> ' + bfaTime + ' - <b>Who Stamped:</b> ' + bfaWho + '</p></div>';
          
        } else if (verified === false) {
          this.mensajeVerificacion = '<div class="alert alert-danger" role="alert"><h5 class="mt-1 mx-auto mb-0" style="text-align:center;"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/><path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/></svg> El certificado no es genuino.</h5></div>';
        } else {
          this.mensajeVerificacion = '<div class="alert alert-warning" role="alert"><h5 class="mt-1 mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-square" viewBox="0 0 16 16"><path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/><path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/></svg> El certificado no pudo ser verificado.</h5></div>';
        }
      })
      .catch(error => {
        console.log(error)
        this.errors.push('Solicitud errónea. Por favor intente nuevamente. Si el problema persiste comuníquese con la mesa de ayuda.');
      })
    },
  }
}
</script>

<style scoped>
h1 {     
  font-size: 2.2rem;
  font-family: 'Lora';
  color: #252525;
  font-weight: 600;
}
.verificar-analitico {
    margin-bottom: 50px;
    min-height: 60vh;
}
.verificar-analitico h1,
.verificar-analitico p{
  text-align: left;
}

.boton-submit  {
    padding: 0.4rem 2rem;
    font-size: .9rem;
    line-height: 1.2;
    border-radius: 3rem;
    background: #252C50;
    border: none !important;
    height: 33px;
    font-family: 'Montserrat';
    font-weight: 400;
    color: white;
    min-width: 100px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.btn:active,
.btn:focus,
.btn:hover {
  color: #fff;
  background: #1b3d65;
}

input[type="file"] {
  height: 33px;
}

a{
  color: #000;
}

</style>
