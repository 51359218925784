<template>
    <div class="welcome mb-5 pb-5">

        <h1 class="mb-3">Certificados INAP</h1>

        <div class="row">
          <div class="col-md-12 txt-home">

            <p class="mt-5">El <strong>Instituto Nacional de la Administración Pública (INAP)</strong> registra en su <strong>Sistema de Acreditación INAP (SAI)</strong> las actividades de capacitación realizadas por los agentes.</p>
              
            <p>Ante la demanda creciente por parte de los agentes de contar con un detalle de las actividades de capacitación promovidas por el INAP o las jurisdicciones, se desarrolló un prototipo que permite generar el certificado correspondiente, al cual se le aplica un sello de tiempo para garantizar su inalterabilidad.</p>
            
            <p>El certificado contiene la totalidad de las actividades de formación concluidas, fechas de realización, disposición que la avala y resultado, información que se encuentra registrada en el SAI. Luego de ser emitido el certificado, es procesado por medio de un mecanismo de validación basado en blockchain denominado  <strong>“sello de tiempo”</strong> que garantiza la inviolabilidad del documento involucrado a partir de la generación de digestos criptográficos que son almacenados en la blockchain. Este proceso asegura que el certificado no recibió modificaciones durante su proceso de generación.</p>
          </div>

        <!-- <div class="col-md-4">
          <img src="@/assets/img-analiticos.png" class="img-fluid mt-4 pl-4" alt="imagen-inap-certificados-analíticos">
        </div> -->
      </div>
    </div>
</template>


<style scoped>
.welcome{
  min-height: 60vh;
}
.welcome h1,
.welcome p{
  text-align: left;
}

h1 {     
  font-size: 2.2rem;
  font-family: 'Lora';
  color: #252525;
  font-weight: 600;
}

@media (min-width:1400px){
    .txt-home{
      padding-right: 4rem;
    }
}
</style>