<template>
  <div>
    <CompHeader/>
    <CompContent/>
    <CompFooter/>
  </div>
</template>

<script>

import CompHeader from './components/CompHeader.vue'
import CompContent from './components/CompContent.vue'
import CompFooter from './components/CompFooter.vue'

export default {
  name: 'App',
  mounted() {
    console.log(`VUE_APP_MODE: Working on '${process.env.VUE_APP_MODE}' mode`)
  },
  components: {
   CompHeader,
   CompContent,
   CompFooter,
  }
}



</script>

<style>
#app {
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #464646;
  background-color: #f7f7f7;
}

/* CHATBOT 2022 - DialogFlow Machine Learning */

df-messenger {
  --df-messenger-bot-message: #F6F6F6;
  --df-messenger-button-titlebar-color: #252C50;
  --df-messenger-chat-background-color: #fafafa;
  --df-messenger-font-color: #51545D;
  --df-messenger-send-icon: #878fac;
  --df-messenger-user-message: #E8E8E7;
}


#chatbot{
  position:absolute;
  z-index: 99999 !important;
}

div.chat-wrapper[opened="true"],
#chatbot div.chat-wrapper[opened="true"]{
  height:450px !important;
}


.mobile-btn{
  padding: .4rem 2rem;
  font-size: .9rem;
  line-height: 22px;
  border-radius: 3rem;
  background: #252C50;
  border: none !important;
  height: 33px;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: white;
  width: 140px;
  text-align: center;
  display: block;
  margin-top: 20px;
  text-decoration: none !important;
}

</style>
